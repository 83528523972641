<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="5">
        <j-card>
          <v-img v-if="blockImage" :lazy-src="blockImage" :src="blockImage" />
          <v-card-title class="pb-0 mb-1 justify-center">
            <h2 class="font-weight-bold mb-4 mt-4 appblue--text">Let's get you started</h2>
          </v-card-title>
          <v-card-text>
            <div class="text-center pb-4">Enter your email address below to begin your transformation.</div>

            <ValidationObserver ref="observer">
              <v-form @submit.prevent="submit">
                <ValidationProvider id="username" v-slot="{ errors }" name="email" rules="required|email">
                  <j-text-field v-model.trim="username" label="Email" :error-messages="errors" @keydown.space.prevent />
                </ValidationProvider>

                <div v-if="displayPassword">
                  <ValidationProvider id="password" v-slot="{ errors }" name="password" rules="required">
                    <j-text-field
                      v-model="password"
                      :append-icon="showPasswordText ? 'visibility' : 'visibility_off'"
                      :type="showPasswordText ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errors"
                      @click:append="showPasswordText = !showPasswordText"
                    />
                  </ValidationProvider>
                </div>

                <j-alert v-if="alertMessage" class="mb-6">
                  <span> {{ alertMessage }} </span>
                </j-alert>

                <j-alert v-if="errorMessage" class="mb-6" type="error">
                  <span> {{ errorMessage }} </span>
                </j-alert>

                <j-btn :loading="buttonLoading" width="100%" @click="buttonClick()">{{ buttonText }}</j-btn>
                <div v-if="displayPassword" class="text-right mt-2">
                  <router-link
                    to="/account/forgot-password"
                    class="secondary--text font-weight-bold body-2 text-decoration-none"
                    >Forgot Password?</router-link
                  >
                </div>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cookies from "js-cookie";
import { CHECK_ACCOUNT_MUTATION } from "@/graphql/mutations/authentication";
import LoginMixin from "@/mixins/LoginMixin";
import { PRODUCT_VARIANT } from "@/graphql/queries/store";

export default {
  name: "Check",
  mixins: [LoginMixin],
  data() {
    return {
      username: "",
      password: "",
      displayPassword: false,
      showPasswordText: false,
      buttonText: "Continue",
      buttonLoading: false,
      alertMessage: "",
      accountStatus: "",
      productVariantId: null,
      productCode: null,
      errorMessage: "",
    };
  },
  computed: {
    premiumBlockUrl() {
      if (this.productCode && this.productVariantId) {
        const pvi = `?pvi=${this.productVariantId}`;
        return `/checkout/${this.productCode}${pvi}`;
      }
      if (this.productVariantId) {
        return `/checkout/premium-block?pvi=${this.productVariantId}`;
      }
      return `/start/pricing`;
    },
    purchaseUrl() {
      // ordering is important here. We first check if a product code is incoming. That supersedes things.
      if (this.productCode) {
        let pvi = "";
        if (this.productVariantId) {
          pvi = `?pvi=${this.productVariantId}`;
        }
        return `/checkout/${this.productCode}${pvi}`;
      } else if (this.productVariantId) {
        return `/start/purchase?pvi=${this.productVariantId}`;
      }

      return `/start/pricing`;
    },
    blockImage() {
      return this.productVariant?.blockSet?.edges[0]?.node?.thumbnailImage;
    },
  },
  methods: {
    buttonClick() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.buttonLoading = true;
          this.alertMessage = "";
          this.errorMessage = "";

          if (this.accountStatus) {
            if (this.accountStatus == "NO_ACCOUNT") {
              sessionStorage.setItem("redirectPath", this.purchaseUrl);
              this.$router.push(`/start/register/?e=${this.username}`);
            } else if (this.accountStatus == "ACTIVE_SUBSCRIPTION") {
              // if not premium block and user is here, we are just going to log them in.
              // overwrite if we have a product code
              let url;
              if (this.productCode && this.productVariantId) {
                const pvi = `?pvi=${this.productVariantId}`;
                url = `/checkout/${this.productCode}${pvi}`;
              } else if (this.productCode) {
                url = `/checkout/${this.productCode}`;
              } else {
                url = this.productVariantId ? `/checkout/premium-block?pvi=${this.productVariantId}` : "/";
              }

              sessionStorage.setItem("redirectPath", url);
              this.login();
            } else if (this.accountStatus == "NO_ACTIVE_SUBSCRIPTION") {
              sessionStorage.setItem("redirectPath", this.purchaseUrl);
              this.login();
            }
          } else {
            this.$apollo
              .mutate({
                mutation: CHECK_ACCOUNT_MUTATION,
                variables: {
                  email: this.username,
                },
              })
              .then(async (result) => {
                const data = result.data.checkAccount;
                this.buttonLoading = false;
                this.accountStatus = data.accountStatus;

                if (data.accountStatus == "NO_ACCOUNT") {
                  this.alertMessage = "Welcome! Continue & create your account.";
                } else if (
                  data.accountStatus == "ACTIVE_SUBSCRIPTION" ||
                  data.accountStatus == "NO_ACTIVE_SUBSCRIPTION"
                ) {
                  this.alertMessage = `Welcome back ${data.firstName}! Please login to continue. `;
                  this.displayPassword = true;
                  this.buttonText = "Login";
                } else {
                  this.alertMessage = "Something went wrong. Please try again.";
                }
              })
              .catch(() => {
                this.buttonLoading = false;
                this.alertMessage = "Something went wrong. Please try again.";
              });
          }
        }
      });
    },
  },
  apollo: {
    productVariant: {
      query: PRODUCT_VARIANT,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.$route.query.pvi;
      },
      variables() {
        return {
          productVariantId: this.$route.query.pvi,
        };
      },
    },
  },
  created() {
    // right now, only handling fbclid. If we have other ids to handle, we should add logic here.
    let attributionId = this.$route.query.fbclid;
    // expires in days
    if (attributionId) {
      Cookies.set("attributionId", attributionId, { expires: 30 });
    } else {
      Cookies.remove("attributionId");
    }

    this.productVariantId = this.$route.query.pvi;
    this.productCode = this.$route.query.pc;

    // ACTIVE_SUBSCRIPTION --> LOGGED IN
    if (this.$store.getters.isLoggedIn && this.$store.getters.subscription != null) {
      if (this.productVariantId) {
        return this.$router.push(this.premiumBlockUrl);
      }

      // if you are already on the highest form of subscription, you should not get redirected to checkout here?
      if (this.productCode && this.$store.getters.role != "ONE_ON_ONE") {
        return this.$router.push(`/checkout/${this.productCode}`);
      }

      return this.$router.push("/");
    } else if (this.$store.getters.isLoggedIn && !this.$store.getters.subscription) {
      // NO_ACTIVE_SUBSCRIPTION --> LOGGED IN
      return this.$router.push(this.purchaseUrl);
    }

    if (process.env.NODE_ENV === "development") {
      const randomString = Math.random().toString(36).slice(2, 10);
      this.username = `${randomString}@test.com`;
    }
  },
};
</script>
