import gql from "graphql-tag";

export const TRANSACTION_STATUS_QUERY = gql`
  query transactionStatus($reference: String!) {
    transactionStatus(gatewayReference: $reference) {
      voucher
      workoutBlocks
      transaction {
        id
        gatewayResponse
        gatewayReference
        transactionStatus
        payment {
          id
          totalAmount
          discountAmount
          order {
            id
            user {
              id
              subscriptions(active: true) {
                edges {
                  node {
                    id
                    product {
                      id
                      type
                      programType
                      requiresIdentification
                    }
                  }
                }
              }
              userProfile {
                id
                isDiscoveryMember
                passportNumber
                idNumber
              }
            }
            currency
            orderproductvariantSet {
              id
              productVariant {
                id
                title
                product {
                  id
                  code
                }
              }
              quantity
              basePrice
            }
          }
        }
      }
    }
  }
`;

export const BILLING_PRODUCTS_QUERY = gql`
  query billingProductsQuery($code: String, $isRecurring: Boolean, $status: String, $withPackages: Boolean!) {
    billingProducts(code: $code, isRecurring: $isRecurring, status: $status) {
      edges {
        node {
          id
          title
          image
          code
          shortDescription
          description
          youtubeId
          requiresActiveSubscription
          allowVoucherRedemption
          isRecurring
          noOverseasShipping
          firstMonthDiscount {
            id
            title
            code
          }
          productvariantSet {
            edges {
              node {
                id
                title
                description
                code
                billingPeriod
                isDefault
                isHidden
                wasPrice
                promotion {
                  id
                  discountAmount
                  discountPercent
                }
                productvariantpriceSet {
                  id
                  price
                  currency
                }
                packageSet {
                  id
                  requiresClub
                  transitionOnEnd
                  title
                  code
                  discount {
                    id
                    title
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
    packages @include(if: $withPackages) {
      id
      title
      image
      description
      code
      youtubeVideo
      longDescription
      startDate
      requiresClub
      transitionOnEnd
      products {
        id
        name
        type
      }
      workoutBlock {
        id
        duration
        startDate
      }
      discount {
        id
        title
        code
        percentageDiscount
        flatDiscount
      }
      productVariant {
        id
        title
        code
        productvariantpriceSet {
          id
          price
        }
        product {
          id
          title
          code
          image
        }
      }
    }
  }
`;

export const BILLING_DETAILS_QUERY = gql`
  query billingAddress {
    me {
      id
      userProfile {
        id
        company
        address
        addressLine2
        suburb
        city
        postcode
        country {
          name
          code
        }
        longitude
        latitude
      }
    }
  }
`;

export const CHECK_VOUCHER_QUERY = gql`
  query checkVoucherQuery($code: String!, $variantIds: [ID]!) {
    voucherDiscounts(voucherCode: $code, productVariantIds: $variantIds) {
      error
      discounts {
        error
        discountAmount
        discountPercent
        resolvedAmount
        isRecurring
      }
    }
  }
`;

export const PASSWORD_TOKEN_QUERY = gql`
  query passwordResetTokenQuery {
    passwordResetToken
  }
`;

export const CART_TOTAL = gql`
  query cartTotalQuery(
    $productVariantIds: [ID]!
    $voucherCode: String
    $currency: String!
    $recurringDiscountCode: String
  ) {
    cartTotal(
      productVariantIds: $productVariantIds
      voucherCode: $voucherCode
      currency: $currency
      recurringDiscountCode: $recurringDiscountCode
    ) {
      baseAmount
      amountAfterDiscounts
      onceOffAmount
      recurringAmount
    }
  }
`;

export const PAYMENT_QUERY = gql`
  query payment($paymentId: ID!) {
    payment(paymentId: $paymentId) {
      order {
        id
        user {
          id
          userProfile {
            id
            activeSubscription {
              id
              acquisitionType
            }
          }
        }
        productVariants {
          edges {
            node {
              id
              billingPeriod
              product {
                id
                title
                successMessage
                isRecurring
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_VARIANT = gql`
  query productVariant($productVariantId: ID!) {
    productVariant(productVariantId: $productVariantId) {
      id
      title
      code
      image
      product {
        id
        title
        code
        image
      }
      blockSet {
        edges {
          node {
            id
            title
            thumbnailImage
            description
            productVariant {
              id
              title
              image
              productvariantpriceSet {
                id
                price
              }
            }
            secondaryProductVariant {
              id
              title
              image
              productvariantpriceSet {
                id
                price
              }
            }
          }
        }
      }
    }
  }
`;
