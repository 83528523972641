<template>
  <div v-if="product">
    <v-avatar v-if="image" size="120" class="mb-4">
      <v-img cover :src="image" />
    </v-avatar>

    <j-card class="pa-2">
      <v-card-title
        class="mt-2 text-heading font-weight-bold secondary--text pa-2 text-center justify-center align-center"
      >
        {{ title }}
      </v-card-title>

      <div v-if="blockDescription" class="p-text mb-4 mt-2">{{ blockDescription }}</div>
      <p v-if="selectedCoach" class="font-weight-bold">
        with Coach {{ selectedCoach }}
        <router-link
          class="text-decoration-none"
          :to="`/start/coaches?redirect=/checkout/join?code=${this.$route.query.code}`"
          ><v-icon size="16" class="ml-1 mt-n1">mdi-pencil</v-icon></router-link
        >
      </p>
    </j-card>
  </div>
</template>

<script>
import { BILLING_PRODUCTS_QUERY } from "@/graphql/queries/store";
import { PRODUCT_VARIANT } from "@/graphql/queries/store";

export default {
  name: "ProductTitleBlock",
  data() {
    return {
      selectedCoach: null,
    };
  },
  created() {
    let selectedCoach = sessionStorage.getItem("selectedCoach");
    if (selectedCoach && this.$route.query?.code?.includes("1-on-1")) {
      selectedCoach = JSON.parse(selectedCoach);
      this.selectedCoach = selectedCoach.coachName;
    }
  },
  computed: {
    image() {
      if (this.workoutBlock?.thumbnailImage) {
        return this.workoutBlock.thumbnailImage;
      }
      if (this.workoutBlock?.image) {
        return this.workoutBlock.image;
      }
      return this.product.image;
    },

    title() {
      if (this.productVariant) {
        return this.productVariant.title;
      }

      return this.product.title;
    },
    blockDescription() {
      if (this.workoutBlock?.description) {
        return this.workoutBlock?.description;
      }
      return null;
    },
    workoutBlock() {
      return this.productVariant?.blockSet?.edges[0]?.node;
    },
  },
  apollo: {
    product: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          code: this.$route.query.code || this.$route.params.code,
          withPackages: false,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.billingProducts.edges.length > 0) {
          let product = data.billingProducts.edges[0].node;
          return product;
        }
        return null;
      },
    },
    productVariant: {
      query: PRODUCT_VARIANT,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.$route.query.pvi;
      },
      variables() {
        return {
          productVariantId: this.$route.query.pvi,
        };
      },
    },
  },
};
</script>
